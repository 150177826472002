<template>
  <v-row class="ma-0 mt-5">
    <v-col cols="12" class="pa-0">
      <v-divider class="py-1" />
    </v-col>
    <v-col class="align-center row ma-0">
      <h3 class="px-3 mb-0">
        결제 예상 금액 : {{ didCampaignService.totalPoint.toLocaleString() }}
      </h3>
    </v-col>
    <v-col cols="auto" class="text-right pb-0">
      <v-btn class="mr-4" @click="back()" v-if="currentStep !== 1" large>
        이전
      </v-btn>
      <DidCampaignServiceReview
        v-if="campaignSteps.length === currentStep"
        :invalid="invalid"
      />
      <v-btn
        v-if="campaignSteps.length !== currentStep"
        color="secondary"
        class="mr-4 text--white"
        @click="nextStep()"
        :disabled="invalid"
        large
      >
        다음
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DidCampaignServiceReview from './DidCampaignServiceReview.vue';
export default {
  components: {
    DidCampaignServiceReview
  },
  props: ['invalid'],
  computed: {
    ...mapGetters({
      didCampaignService: 'didCampaignService/didCampaignService',
      currentStep: 'didCampaignService/currentStep',
      campaignSteps: 'didCampaignService/didCampaignServiceSteps'
    })
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'didCampaignService/setCurrentStep'
    }),
    /** @description: 이전페이지 이동 */
    back() {
      let step = this.currentStep;

      this.setCurrentStep(step - 1);
    },
    /** @description: 다음페이지 이동 */
    nextStep() {
      const step = this.currentStep;

      this.setCurrentStep(step + 1);
    }
  }
};
</script>

<style></style>
